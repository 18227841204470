<template>
  <v-container class="page-container" fill-height fluid grid-list-xl
  >
    <v-layout wrap id="configuration">
      <h1 class="page-title text-xs-left mb-3 w-100">{{header_title}}</h1>
      <div class="flex-display flex-row flex-justify-end mb-3 w-100">
        <button class="btn add-new" @click="showPricingModal('add',null)"><i class="v-icon mdi mdi-plus"></i>{{add_button_text}}</button>
      </div>

      <div class="session-package w-100" v-if="isReady">
        <template v-if="pricing.length > 0">
          <div class="item-container"
               v-for="p in pricing"
               :key="p.id"
          >
            <p class="item-text">{{p.title}}</p>
            <div class="item-actions">
              <button class="btn-edit width-auto px-2" @click="redirectToSelfCarePricingItem(p)">Self-care Pricing Items</button>
              <button class="btn-edit width-auto ml-2 px-2" @click="redirectToTherapyPricingItem(p)">Therapy Pricing Items</button>
              <button class="btn-edit ml-2" @click="editPricing(p)">Edit</button>
              <button class="btn-delete ml-2" @click="showDeleteConfirmation(p)">Delete</button>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="text-center w-100 py-3">No pricing found! please add a new pricing</p>
        </template>
      </div>

      <!-- Add Pricing -->
      <add-pricing v-if="pricingModalDialog"
                           :visible="pricingModalDialog"
                           :action="action"
                           :value="selectedPricing"
                           v-on:submitPricing="addPricing"
                           v-on:hide="hidePricingModal"
      >
      </add-pricing>
      <!-- delete dialog -->
      <confirmation v-if="confirmationDialog" :visible="confirmationDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This pricing will be removed. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideDeleteConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removePricing">Delete</button>
        </template>
      </confirmation>
    </v-layout>

  </v-container>
</template>

<script>
import AddPricing from '@/components/configuration/Pricing/AddPricing'
import Confirmation from '@/components/common/Confirmation'
export default {
  name: "Pricing",
  components: {
    AddPricing,
    Confirmation,
  },
  data: () => ({
    header_title: 'Pricing',
    add_button_text: 'New Pricing',
    action: 'add',
    isReady: false,
    disableActions: false,
    pricingModalDialog: false,
    confirmationDialog: false,
    pricing: [],
    selectedPricing: null,
  }),
  created() {
    this.getPricing();
  },
  methods:{
    showPricingModal() {
      this.pricingModalDialog = true;
    },
    hidePricingModal() {
      this.action = 'add';
      this.selectedPricing = null;
      this.pricingModalDialog = false;
    },
    showDeleteConfirmation(p) {
      this.selectedPricing = p;
      this.confirmationDialog = true;
    },
    hideDeleteConfirmation() {
      this.confirmationDialog = false;
    },
    getPricing() {
      let loader = this.$loading.show();
      this.disableActions = true;
      this.$http.get(this.url+'pricing', {
      }).then((response) => {
        loader.hide();
        this.isReady = true;
        this.disableActions = false;
        this.pricing = response.data.pricing;
      }).catch((error) => {
        loader.hide();
        this.isReady = true;
        this.disableActions = false;
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    editPricing(p) {
      this.action = 'update';
      this.selectedPricing = p;
      this.showPricingModal();
    },
    redirectToSelfCarePricingItem(pricing) {
      window.location = "selfCarePricingItems/" + pricing.id;
    },
    redirectToTherapyPricingItem(pricing) {
      window.location = "therapyPricingItems/" + pricing.id;
    },
    addPricing(data, action) {
      let loader = this.$loading.show();
      data.action = action;
      this.$http.put(this.url+'pricing', data).then((response) => {
        loader.hide();
        this.hidePricingModal();
        this.$toastr('success', response.data.message);
        this.getPricing();
      }).catch((error) => {
        loader.hide();
        this.$toastr('error', error.response.data.message);
      });
    },
    removePricing() {
      let self = this;
      let loader = this.$loading.show();
      self.hideDeleteConfirmation();
      self.$http.delete(this.url+'pricing', {
        data: {
          id: self.selectedPricing.id
        }
      }).then(function (response) {
        loader.hide();
        self.selectedPricing = null;
        self.$toastr('success', response.data.message);
        self.getPricing();
      }).catch(function (error) {
        loader.hide();
        self.$toastr('error',error.response.data.message);
      })
    }
  }
}
</script>
<style scoped>
 .width-auto {
   width: auto !important;
 }
</style>
